import "./PP_TNC.css";

import React from "react";
import { RESUMEZ_EMAIL_LINK } from "../constants";
import AppBarHeader from "../Components/AppBarHeader";
import Footer from "../Components/Footer";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <AppBarHeader />
      <div className="ppTncPageWrapper">
      <section className="ppTncSectionWrapper">
        <h1 className="ppTncPageHeader">Privacy Policy</h1>
        {/* Add your privacy policy content here */}
        <p className="ppTncPageSubHeader">
          Privacy Policy for resumez.in, Effective Date: {new Date().toISOString().split("T")[0]}
          <br />
          At resumez.in, we prioritize the privacy and security of our users. <br /> This Privacy Policy outlines how we
          handle your personal information and data when you use our resume builder services.
        </p>
        <ol className="listOrdered">
          <li>
            <h3 className="ulHeader">Data Storage</h3>
            <ul className="listUnordered">
              <li>
                We use your browser’s local storage to store your resume data while you build or edit your resume. This
                data remains stored locally on your device and is not collected or shared with our servers unless you
                explicitly choose to save your resume on our platform.
              </li>
              <li>
                If you do not save your resume to our servers, your data remains private and accessible only through
                your local device.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="ulHeader">Data Collection</h3>
            <ul className="listUnordered">
              <li>
                We do not collect any personal data unless you voluntarily choose to save your resume to our servers.
              </li>
              <li>
                When you save your resume to our servers, we may collect and store the personal information necessary to
                facilitate the storage and retrieval of your resume.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="ulHeader">Third-Party Services</h3>
            <ul className="listUnordered">
              <li>
                This Application utilizes third-party services that have their own Privacy Policy about handling data.
                Below are the application's Privacy Policy of the third-party service providers used by the Application:
                <br />
                - Google Analytics for Firebase
                <br />- Firebase Crashlytics
              </li>
              <li>
                We use Google AdSense to display advertisements on our website. Google AdSense may collect certain
                information about your browsing activity to deliver targeted ads. For more information on how Google
                collects and uses data, please review their privacy policy here.
              </li>
              <li>
                You can adjust your privacy settings through Google’s ad preferences to control the type of ads you see.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="ulHeader">Sharing of Information</h3>
            <ul className="listUnordered">
              <li>resumez.in does not share or sell your personal information to third parties.</li>
              <li>
                We will not release your personal information to authorities unless required by law, search warrant,
                court order, or as part of a fraud investigation.
              </li>
            </ul>
          </li>
          <li>
            <h3 className="ulHeader">Your Consent</h3>
            <ul className="listUnordered">
              <li>By using resumez.in, you consent to our Privacy Policy and agree to its terms.</li>
            </ul>
          </li>
          <li>
            <h3 className="ulHeader">hanges to This Policy</h3>
            <ul className="listUnordered">
              <li>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with an
                updated effective date.
              </li>
            </ul>
          </li>
        </ol>
        <p style={{ display: "inline" }}>
          If you have any questions or concerns regarding this Privacy Policy, please contact us at{" "}
          <a href={`mailto:${RESUMEZ_EMAIL_LINK}`} target="_blank">
            {RESUMEZ_EMAIL_LINK}
          </a>
          .
        </p>
      </section></div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
