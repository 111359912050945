import React, { FC } from "react";
import { BarRatings, Ratings, StarRatings } from "../../../interfaces/Ratings";
import { BlueprintWrapper } from "./_BlueprintStyles";
import { BlueprintTitle } from "./_BlueprintTitle";
import { useAppSelector } from "../../../app/hooks";
import {
  CircleFilledComponentIcon,
  CircleOutlinedComponentIcon,
  SquareFilledComponentIcon,
  SquareOutlinedComponentIcon,
  StarFilledComponentIcon,
  StarOutlinedComponentIcon,
} from "../../../static/Icons/ComponentIcons";

interface StarRatingBlueprintProps {
  ratings: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: StarRatings;
  };
}
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS Blueprint #1
export const RatingsBlueprint1: FC<StarRatingBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);

  return (
    <>
      <BlueprintWrapper
        h={props.ratings.h}
        x={props.ratings.x}
        y={props.ratings.y}
        w={props.ratings.w}
        bgColor={props.ratings.data.style.bgColor}
        textColor={props.ratings.data.style.textColor}
        fontFamily={formStyles.fontFamily}
      >
        <BlueprintTitle formStyles={formStyles} title={props.ratings.data.title} flipped={props.ratings.data.flipped} />
        <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, marginTop: "8px" }}>
          {props.ratings.data.data.map((eachRating, i) => {
            return (
              <div
                key={eachRating.ratingSubject + i}
                style={{
                  display: "flex",
                  flexDirection: props.ratings.data.flipped ? "row-reverse" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <div style={{ margin: "0px 0px 2px 0px" /* for aligning with the starts */ }}>
                  {eachRating.ratingSubject}
                </div>
                <div>
                  {eachRating.rateInPercentage === undefined ? (
                    eachRating.rateInPercentage
                  ) : (
                    <div>
                      {getStarsArray(
                        Math.round(eachRating.rateInPercentage / 20),
                        props.ratings.data.icon,
                        formStyles.accentColor,
                        22,
                        props.ratings.data.flipped
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </BlueprintWrapper>
    </>
  );
};
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS Blueprint #2
export const RatingsBlueprint2: FC<StarRatingBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);

  return (
    <>
      <BlueprintWrapper
        h={props.ratings.h}
        x={props.ratings.x}
        y={props.ratings.y}
        w={props.ratings.w}
        bgColor={props.ratings.data.style.bgColor}
        textColor={props.ratings.data.style.textColor}
        fontFamily={formStyles.fontFamily}
      >
        <BlueprintTitle formStyles={formStyles} title={props.ratings.data.title} flipped={props.ratings.data.flipped} />

        <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, marginTop: "8px" }}>
          {props.ratings.data.data.map((eachRating, i) => {
            return (
              <div
                key={eachRating.ratingSubject + i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: props.ratings.data.flipped ? "flex-end" : "flex-start",
                  margin: "10px 0px",
                }}
              >
                <div style={{ margin: "0px 0px 2px 0px" /* for aligning with the starts */ }}>
                  {eachRating.ratingSubject}
                </div>
                <div>
                  {eachRating.rateInPercentage === undefined ? (
                    eachRating.rateInPercentage
                  ) : (
                    <div>
                      {getStarsArray(
                        Math.round(eachRating.rateInPercentage / 20),
                        props.ratings.data.icon,
                        formStyles.accentColor,
                        20,
                        props.ratings.data.flipped
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </BlueprintWrapper>
    </>
  );
};

interface BarRatingBlueprintProps {
  ratings: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: BarRatings;
  };
}
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS Blueprint #3
export const RatingsBlueprint3: FC<BarRatingBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);

  return (
    <>
      <BlueprintWrapper
        h={props.ratings.h}
        x={props.ratings.x}
        y={props.ratings.y}
        w={props.ratings.w}
        bgColor={props.ratings.data.style.bgColor}
        textColor={props.ratings.data.style.textColor}
        fontFamily={formStyles.fontFamily}
      >
        <BlueprintTitle formStyles={formStyles} title={props.ratings.data.title} flipped={props.ratings.data.flipped} />

        <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, marginTop: "8px" }}>
          {props.ratings.data.data.map((eachRating, i) => {
            return (
              <div
                key={eachRating.ratingSubject + i}
                style={{
                  display: "flex",
                  flexDirection: props.ratings.data.flipped ? "row-reverse" : "row",
                  textAlign: props.ratings.data.flipped ? "right" : "left",
                  alignItems: "center",
                  margin: "10px 0px",
                  width: "100%",
                }}
              >
                <div style={{ margin: "0px 0px 4px 0px", width: "40%" }}>{eachRating.ratingSubject}</div>
                <div style={{ width: "60%" }}>
                  {eachRating.rateInPercentage === undefined
                    ? eachRating.rateInPercentage
                    : getProgressBar(
                        eachRating.rateInPercentage,
                        formStyles.accentColor,
                        props.ratings.data.barRadius,
                        props.ratings.data.barWidth,
                        props.ratings.data.flipped
                      )}
                </div>
              </div>
            );
          })}
        </div>
      </BlueprintWrapper>
    </>
  );
};
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS Blueprint #3
export const RatingsBlueprint4: FC<BarRatingBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);

  return (
    <>
      <BlueprintWrapper
        h={props.ratings.h}
        x={props.ratings.x}
        y={props.ratings.y}
        w={props.ratings.w}
        bgColor={props.ratings.data.style.bgColor}
        textColor={props.ratings.data.style.textColor}
        fontFamily={formStyles.fontFamily}
      >
        <BlueprintTitle formStyles={formStyles} title={props.ratings.data.title} flipped={props.ratings.data.flipped} />

        <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, marginTop: "8px" }}>
          {props.ratings.data.data.map((eachRating, i) => {
            return (
              <div
                key={eachRating.ratingSubject + i}
                style={{
                  margin: "8px 0px",
                  width: "100%",
                  textAlign: props.ratings.data.flipped ? "right" : "left",
                }}
              >
                <div style={{ margin: "0px 0px 4px 0px" }}>{eachRating.ratingSubject}</div>
                <div>
                  {eachRating.rateInPercentage === undefined
                    ? eachRating.rateInPercentage
                    : getProgressBar(
                        eachRating.rateInPercentage,
                        formStyles.accentColor,
                        props.ratings.data.barRadius,
                        props.ratings.data.barWidth,
                        props.ratings.data.flipped
                      )}
                </div>
              </div>
            );
          })}
        </div>
      </BlueprintWrapper>
    </>
  );
};

function getStarsArray(
  numberOfRatesInStar: number,
  type: string,
  color: string,
  size: number,
  flipped: boolean
): React.ReactNode {
  // const totalStars = 5;
  // const filledStars = flipped ? totalStars - numberOfRatesInStar : numberOfRatesInStar;
  // const emptyStars = totalStars - filledStars;

  return flipped ? (
    <div>
      {[...Array(5 - numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_empty", i, color, size)}</span>;
      })}
      {[...Array(numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_fill", i, color, size)}</span>;
      })}
    </div>
  ) : (
    <div>
      {[...Array(numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_fill", i, color, size)}</span>;
      })}
      {[...Array(5 - numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_empty", i, color, size)}</span>;
      })}
    </div>
  );
}
// function chooseIcon(iconName: string, key: number, color: string, size: number) {
//   switch (iconName) {
//     case "star_fill":
//       return <StarRoundedIcon key={key + "_fill"} style={{ color: color, fontSize: size + 4 }} />;
//     case "star_empty":
//       return <StarOutlineRoundedIcon key={key + "_empty"} style={{ color: color, fontSize: size + 4 }} />;
//     case "circle_fill":
//       return <CircleIcon key={key + "_fill"} style={{ color: color, fontSize: size, margin: "2px 2px" }} />;
//     case "circle_empty":
//       return <CircleOutlinedIcon key={key + "_empty"} style={{ color: color, fontSize: size, margin: "2px 2px" }} />;
//     case "square_fill":
//       return <SquareRoundedIcon key={key + "_fill"} style={{ color: color, fontSize: size, margin: "2px 2px" }} />;
//     case "square_empty":
//       return <CropDinRoundedIcon key={key + "_empty"} style={{ color: color, fontSize: size, margin: "2px 2px" }} />;
//     default:
//       break;
//   }
// }
function chooseIcon(iconName: string, key: number, color: string, size: number) {
  const iconStyle = { display: "inline", padding: "2px", color: color };
  switch (iconName) {
    case "star_fill":
      return (
        <div style={iconStyle}>
          <StarFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "star_empty":
      return (
        <div style={iconStyle}>
          <StarOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    case "circle_fill":
      return (
        <div style={iconStyle}>
          <CircleFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "circle_empty":
      return (
        <div style={iconStyle}>
          <CircleOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    case "square_fill":
      return (
        <div style={iconStyle}>
          <SquareFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "square_empty":
      return (
        <div style={iconStyle}>
          <SquareOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    default:
      break;
  }
}

function getProgressBar(percentage: number, color: string, barRadius: number, barWidth: number, flipped: boolean) {
  return flipped ? (
    <div style={{ width: "100%", height: `${barWidth}px`, display: "flex" }}>
      <div
        style={{
          display: percentage === 100 ? "none" : "block",
          width: `${100 - percentage}%`,
          backgroundColor: "transparent",
          border: `2px solid ${color}`,
          borderRadius: percentage === 0 ? `${barRadius}px` : `${barRadius}px 0px 0px ${barRadius}px`,
        }}
      >
        &nbsp;
      </div>
      <div
        style={{
          display: percentage === 0 ? "none" : "block",
          width: `${percentage}%`,
          backgroundColor: color,
          border: `2px solid ${color}`,
          borderRadius: percentage === 100 ? `${barRadius}px` : `0px ${barRadius}px ${barRadius}px 0px`,
        }}
      >
        &nbsp;
      </div>
    </div>
  ) : (
    <div style={{ width: "100%", height: `${barWidth}px`, display: "flex" }}>
      <div
        style={{
          display: percentage === 0 ? "none" : "block",
          width: `${percentage}%`,
          backgroundColor: color,
          border: `2px solid ${color}`,
          borderRadius: percentage === 100 ? `${barRadius}px` : `${barRadius}px 0px 0px ${barRadius}px`,
        }}
      >
        &nbsp;
      </div>
      <div
        style={{
          display: percentage === 100 ? "none" : "block",
          width: `${100 - percentage}%`,
          backgroundColor: "transparent",
          border: `2px solid ${color}`,
          borderRadius: percentage === 0 ? `${barRadius}px` : `0px ${barRadius}px ${barRadius}px 0px`,
        }}
      >
        &nbsp;
      </div>
    </div>
  );
}
