import "./Footer.css";

import * as React from "react";

import {
  MY_GITHUB_LINK,
  MY_LINKEDIN_LINK,
  RAZORPAY_DONATION_SITE_PROD,
  RAZORPAY_DONATION_SITE_TEST,
  RESUMEZ_DISCORD_LINK,
  RESUMEZ_EMAIL_LINK,
  RESUMEZ_INSTAGRAM_LINK,
  _isProd,
} from "../constants";
import { DiscordIcon, EmailIcon, GithubIcon, LinkedInIcon, InstagramIcon } from "../static/Icons/SocialMediaIcons";
import { IconButtonComponent } from "./Inputs/IconButtonComponent";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const gotoLink = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <footer className="footer" id="contact">
        <div className="footerContent">
          <div className="footerColumn">
            <p style={{ fontSize: "1rem", marginTop: 12 }}>Thank You For Visiting This Site.</p>
            <p style={{ fontSize: "1rem" }}>Contact Information:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <IconButtonComponent
                size="medium"
                onClick={() => gotoLink(MY_GITHUB_LINK)}
                iconColor="#555"
                // size="large"
                // style={{ padding: 8, margin: "8px 8px 8px 0px", borderRadius: 8, color: "#333" }}
              >
                <GithubIcon />
              </IconButtonComponent>
              <IconButtonComponent size="medium" onClick={() => gotoLink(MY_LINKEDIN_LINK)} iconColor="#1e88e5">
                <LinkedInIcon />
              </IconButtonComponent>
              <IconButtonComponent size="medium" onClick={() => gotoLink(RESUMEZ_INSTAGRAM_LINK)} iconColor="#E4405F">
                <InstagramIcon />
              </IconButtonComponent>
              <IconButtonComponent
                size="medium"
                onClick={() => gotoLink(`mailto:${RESUMEZ_EMAIL_LINK}.com`)}
                iconColor="#EA4335"
              >
                <EmailIcon />
              </IconButtonComponent>
              <IconButtonComponent size="medium" onClick={() => gotoLink(RESUMEZ_DISCORD_LINK)} iconColor="#5b6be6">
                <DiscordIcon />
              </IconButtonComponent>
            </div>
          </div>
          <div className="footerColumn">
            <a
              className="donateButton"
              href={_isProd ? RAZORPAY_DONATION_SITE_PROD : RAZORPAY_DONATION_SITE_TEST}
              target="_blank"
            >
              Donate to Resumez
            </a>
          </div>
          <div className="footerColumn">
            <a style={{ fontSize: "2rem", marginBottom: "8px", fontWeight: "600" }} href="/">
              Resumez
            </a>
            <p style={{ fontSize: "0.8rem" }}>©{new Date().getFullYear()} Copyright: Resumez</p>
            <p style={{ fontSize: "0.8rem" }}>All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
